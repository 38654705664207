import React from 'react';
import Badge from 'react-bootstrap/Badge';
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import Tooltip from 'react-bootstrap/Tooltip';

const RateCell = ({ value, highest, lowest, highlightHighest }) => {
    return (
        <div style={{ display: 'flex', justifyContent: 'space-between' }}>
            <div>
                {value === highest && 
                    <Badge pill bg={highlightHighest ? "success" : "warning"} className="me-1" style={{ cursor: 'pointer' }}>
                        <OverlayTrigger placement="top" overlay={<Tooltip>Highest</Tooltip>}>
                            <span>H</span>
                        </OverlayTrigger>
                    </Badge>}
                {value === lowest && 
                    <Badge pill bg={highlightHighest ? "warning" : "success"} className="me-1" style={{ cursor: 'pointer' }}>
                        <OverlayTrigger placement="top" overlay={<Tooltip>Lowest</Tooltip>}>
                            <span>L</span>
                        </OverlayTrigger>
                    </Badge>}
            </div>
            <div style={{ textAlign: 'right' }}>
                {value}
            </div>
        </div>
    );
}

export default RateCell;