import React from 'react';
import './App.css';
import Row from 'react-bootstrap/Row';
import Table from 'react-bootstrap/Table';
import Col from 'react-bootstrap/Col';
import Container from 'react-bootstrap/Container';
import Placeholder from 'react-bootstrap/Placeholder';
import NavbarComponent from './components/Navbar';
import CurrencySelector from './components/CurrencySelector';
import FieldSelector from './components/FieldSelector';
import InfoModal from './components/InfoModal';
import Footer from './components/Footer';
import TableHeader from './components/TableHeader';
import PlaceholderRows from './components/PlaceholderRows';
import ExchangeRatesRows from './components/ExchangeRatesRows';
import ReactGA from 'react-ga4';
import TagManager from 'react-gtm-module';
import Toast from './components/Toast';
import Badge from 'react-bootstrap/Badge';

class App extends React.Component {
  constructor(props) {
    super(props);
    // Initialize Google Analytics
    ReactGA.initialize('G-W36DX0NB85');
    // Initialize Google Tag Manager
    const tagManagerArgs = {
      gtmId: 'GTM-WZXBP3M5'
    };
    TagManager.initialize(tagManagerArgs);    
    this.state = {
      currencies: [],
      exchangeRates: [],
      selectedCurrency: localStorage.getItem('selectedCurrency') || 'USD',
      selectedField: localStorage.getItem('selectedField') || 'Telegraphic Transfers',
      showModal: false,
      modalData: {
        bank_name: '',
        timestamp: '',
        exchange_source: ''
      },
      showToast: false,
      toastMessage: '',
      sortField: null,
      sortDirection: 'asc' // or 'desc'
    };

  }

  
  handleSort = (field) => {
    const direction = this.state.sortField === field && this.state.sortDirection === 'asc' ? 'desc' : 'asc';
    const sortedRates = [...this.state.exchangeRates].sort((a, b) => {
      if (a[field] === null || a[field] === undefined) return 1;
      if (b[field] === null || b[field] === undefined) return -1;
      if (direction === 'asc') {
        return a[field] > b[field] ? 1 : -1;
      } else {
        return a[field] < b[field] ? 1 : -1;
      }
    });
    this.setState({ exchangeRates: sortedRates, sortField: field, sortDirection: direction });
  }

  async componentDidMount() {
    // Track page view
    ReactGA.send('pageview');
    const storedCurrencies = localStorage.getItem('currencies');
    const storedBankMeta = localStorage.getItem('bankMeta');
    const storedTimestamp = localStorage.getItem('metaDataTimestamp');
  
    const oneWeek = 7 * 24 * 60 * 60 * 1000; // one week in milliseconds
  
    if (storedCurrencies && storedBankMeta && storedTimestamp && (Date.now() - storedTimestamp < oneWeek)) {
      this.setState({ 
        currencies: JSON.parse(storedCurrencies),
        bankMeta: JSON.parse(storedBankMeta)
      });
    } else {
      const data = await this.fetchApi(`${process.env.REACT_APP_API_ENDPOINT}/bank-meta`);
      if (data) {
        const banks = data['banks'];
        this.setState({ bankMeta: banks });
        localStorage.setItem('bankMeta', JSON.stringify(banks));
        const currencies = data['currencies']
        this.setState({ currencies: currencies });
        localStorage.setItem('currencies', JSON.stringify(currencies));          
      }
      localStorage.setItem('metaDataTimestamp', Date.now());
    }
  
    if (this.state.selectedCurrency) {
      this.fetchExchangeRates(this.state.selectedCurrency);
    }
     // Add event listener for visibilitychange event
    document.addEventListener('visibilitychange', this.handleVisibilityChange);
  }

  
  componentWillUnmount() {
    // Remove event listener when the component is unmounted
    document.removeEventListener('visibilitychange', this.handleVisibilityChange);
  }

  handleVisibilityChange = () => {
    if (document.visibilityState === 'visible') {
      // The app is visible, refresh the table data
      this.fetchExchangeRates(this.state.selectedCurrency);
    }
  }
  

  fetchExchangeRates = async (currency) => {

    this.setState({exchangeRates: []});


    const cachedData = localStorage.getItem(`exchangeRates-${currency}`);
    const cachedTimestamp = localStorage.getItem(`timestamp-${currency}`);
    if (cachedData && cachedTimestamp) {
      const now = new Date();
      const cachedDate = new Date(parseInt(cachedTimestamp));
      const diffHours = Math.abs(now - cachedDate) / 36e5; // difference in hours
  
      const isMorningTime = cachedDate.getUTCHours() === 4 && cachedDate.getUTCMinutes() >= 30;
      const isAfternoonTime = cachedDate.getUTCHours() === 10 && cachedDate.getUTCMinutes() >= 30;
  
      if ((isMorningTime && diffHours < 6) || (isAfternoonTime && diffHours < 18)) {
        await new Promise(resolve => setTimeout(resolve, 350));
        this.setState({ exchangeRates: JSON.parse(cachedData) });
        return;
      }
    }
  
    
  
    const data = await this.fetchApi(`${process.env.REACT_APP_API_ENDPOINT}/exchange-rates?currency=${currency}`);  
    if (data) {
      const latestTimestamp = this.getLatestTimestamp(data);
      this.setState({ exchangeRates: data });
      localStorage.setItem(`exchangeRates-${currency}`, JSON.stringify(data));
      localStorage.setItem(`timestamp-${currency}`, latestTimestamp);
    }
  }
  
  getLatestTimestamp = (data) => {
    return Math.max(...data.map(rate => rate.timestamp));
  }

  handleCurrencyChange = (event) => {
    const selectedCurrency = event.target.value;
    ReactGA.event({
      category: 'Dropdown',
      action: 'Currency dropdown selected',
      label: selectedCurrency
    });
    this.setState({selectedCurrency});
    localStorage.setItem('selectedCurrency', selectedCurrency);
    this.fetchExchangeRates(selectedCurrency);
  }

  handleFieldChange = (event) => {
    const selectedField = event.target.value;
    ReactGA.event({
      category: 'Dropdown',
      action: 'Field dropdown selected',
      label: selectedField
    });    
    this.setState({ selectedField });
    localStorage.setItem('selectedField', selectedField); // Store the value in local storage
  }

  handleIconClick = (bank_name, timestamp, exchange_source) => {
    ReactGA.event({
      category: 'Button',
      action: 'Information icon clicked',
      label: bank_name
    });  
    this.setState({
      showModal: true,
      modalData: { bank_name, timestamp, exchange_source }
    });
  }

  handleModalClose = () => {
    this.setState({ showModal: false });
  }

  formatDateTime = (timestamp) => {
    return new Date(timestamp).toLocaleString([], {year: 'numeric', month: '2-digit', day: '2-digit', hour: '2-digit', minute: '2-digit'});
  }
  
  formatTime = (timestamp) => {
    return new Date(timestamp).toLocaleString([], {hour: '2-digit', minute: '2-digit'});
  }

  fetchApi = async (url) => {
    try {
      const response = await fetch(url);
      if (!response.ok) {
        throw new Error(`HTTP error! status: ${response.status}`);
      }
      const data = await response.json();
      return data;
    } catch (error) {
      // console.error('There was a problem with the fetch operation: ', error);
      if (!navigator.onLine) {
        this.setState({
          showToast: true,
          toastMessage: 'You are currently offline. Please check your internet connection.',
        });
      } else {
        this.setState({
          showToast: true,
          toastMessage: 'There was a problem when calling the server. Please check again in few minutes.',
        });
      }
    }
  }



  render() {
    const calculateMinMax = (values) => {
      let highest, lowest;
      if (values.length > 0) {
        highest = Math.max(...values);
        lowest = Math.min(...values);
      }
      return { highest, lowest };
    }

    const ttBuyingValues = this.state.exchangeRates.map(rate => rate.tt_buying).filter(value => !isNaN(value));
    const ttSellingValues = this.state.exchangeRates.map(rate => rate.tt_selling).filter(value => !isNaN(value));
    const curBuyingValues = this.state.exchangeRates.map(rate => rate.cur_buying).filter(value => !isNaN(value));
    const curSellingValues = this.state.exchangeRates.map(rate => rate.cur_selling).filter(value => !isNaN(value));
    const ddBuyingValues = this.state.exchangeRates.map(rate => rate.dd_buying).filter(value => !isNaN(value));
    const ddSellingValues = this.state.exchangeRates.map(rate => rate.dd_selling).filter(value => !isNaN(value));

    const { highest: highestTtBuying, lowest: lowestTtBuying } = calculateMinMax(ttBuyingValues);
    const { highest: highestTtSelling, lowest: lowestTtSelling } = calculateMinMax(ttSellingValues);
    const { highest: highestCurBuying, lowest: lowestCurBuying } = calculateMinMax(curBuyingValues);
    const { highest: highestCurSelling, lowest: lowestCurSelling } = calculateMinMax(curSellingValues);
    const { highest: highestDdBuying, lowest: lowestDdBuying } = calculateMinMax(ddBuyingValues);
    const { highest: highestDdSelling, lowest: lowestDdSelling } = calculateMinMax(ddSellingValues);

    const latestTimestamp = this.getLatestTimestamp(this.state.exchangeRates);
        
    return (
      <div className="App" style={{ display: 'flex', flexDirection: 'column', minHeight: '100vh' }}>
        <NavbarComponent />
        <main>
          <Container style={{ flex: '1 0 auto' }}>
            <Row className="mb-3 mb-md-2">
              <Col md={3} className="mb-3 mb-md-2">
                <CurrencySelector 
                  currencies={this.state.currencies} 
                  selectedCurrency={this.state.selectedCurrency} 
                  onCurrencyChange={this.handleCurrencyChange} 
                />
              </Col>
              <Col md={3}>
                <FieldSelector 
                  selectedField={this.state.selectedField} 
                  onFieldChange={this.handleFieldChange} 
                />
              </Col>        
            </Row>

            
            <Row>
              {this.formatDateTime(latestTimestamp) === "Invalid Date" ? (
                <Col md={3} className="mb-3 mb-md-3">               
                  <Placeholder animation="glow">
                    <Placeholder xs={12} />
                  </Placeholder>
                </Col>
              ) : (
                <Col md={12} >                
                  <p><small>Last updated time {this.formatDateTime(latestTimestamp)}.</small></p>
                </Col>
              )}

            </Row>
            <Row className="mb-3 mb-md-2">
              <Col>
                <Table bordered hover striped responsive >               
                {/* hover  striped  size="sm"   variant="light"*/}
                  <TableHeader 
                    selectedField={this.state.selectedField} 
                    onHeaderClick={this.handleSort} 
                    sortField={this.state.sortField} 
                    sortDirection={this.state.sortDirection} 
                  />
                  {this.state.exchangeRates.length === 0 ? (
                    <PlaceholderRows selectedField={this.state.selectedField} />
                  ) : (
                    <ExchangeRatesRows 
                      exchangeRates={this.state.exchangeRates} 
                      selectedField={this.state.selectedField} 
                      bankMeta={this.state.bankMeta} 
                      handleIconClick={this.handleIconClick}
                      highestTtBuying={highestTtBuying}
                      lowestTtBuying={lowestTtBuying}
                      highestTtSelling={highestTtSelling}
                      lowestTtSelling={lowestTtSelling}
                      highestCurBuying={highestCurBuying}
                      lowestCurBuying={lowestCurBuying}
                      highestCurSelling={highestCurSelling}
                      lowestCurSelling={lowestCurSelling}
                      highestDdBuying={highestDdBuying}
                      lowestDdBuying={lowestDdBuying}
                      highestDdSelling={highestDdSelling}
                      lowestDdSelling={lowestDdSelling}
                    />
                  )}
                </Table>             
              </Col>
            </Row>
            <Row>
                <Col md={12} >                   
                  <p style={{textAlign: "justify"}}><small>Rates are updated at {this.formatTime(1697776200000)} and {this.formatTime(1697797800000)} daily. All times mentioned in this site are adjusted as per your timezone.</small></p>                  
                </Col>
            </Row>
            <Row>
              <Col md={12} >
                <p style={{textAlign: "justify"}}><small>
                  Badge <Badge pill bg="secondary" className="me-1" style={{ cursor: 'pointer' }}>
                  <span>H</span>
                </Badge>
                   means Highest. Badge <Badge pill bg="secondary" className="me-1" style={{ cursor: 'pointer' }}>
                    <span>L</span>
                  </Badge> means Lowest.
                  If a badge is  <Badge pill bg="success" className="me-1" style={{ cursor: 'pointer' }}>
                  <span>Green</span>
                </Badge> that means it is profitable for you. If a badge is <Badge pill bg="warning" className="me-1" style={{ cursor: 'pointer' }}>
                    <span className='text-dark'>Yellow</span>
                  </Badge>  that is profitable for the bank.
                </small></p>
              </Col>
            </Row>
          </Container>
        </main>

        <Footer />

        <InfoModal 
          show={this.state.showModal} 
          handleClose={this.handleModalClose} 
          modalData={this.state.modalData} 
          formatDateTime={this.formatDateTime} 
        />

        {this.state.showToast && 
          <div className="modal-backdrop show"></div>
        }

        <Toast 
            onClose={() => this.setState({ showToast: false })} 
            show={this.state.showToast} 
            delay={5000} 
            autohide 
            toastMessage={this.state.toastMessage}
          />
      </div>
    );
  }
}

export default App;