import React from 'react';
import Modal from 'react-bootstrap/Modal';
import Button from 'react-bootstrap/Button';

const InfoModal = ({show, handleClose, modalData, formatDateTime}) => {
  return (
    <Modal show={show} onHide={handleClose} centered>
      <Modal.Header closeButton>
        <Modal.Title>{modalData.bank_name}</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <p>Data fetched on: {formatDateTime(modalData.timestamp)}</p>
        <p>Data Source: <a href={modalData.exchange_source} target="_blank" rel="noopener noreferrer">Link</a></p>
      </Modal.Body>
      <Modal.Footer>
        <Button variant="secondary" onClick={handleClose}>
          Close
        </Button>
      </Modal.Footer>
    </Modal>
  );
}

export default InfoModal;