import React from 'react';
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import Tooltip from 'react-bootstrap/Tooltip';
import SortableTableHeaderCell from './SortableTableHeaderCell';

const TableHeader = ({ selectedField, onHeaderClick, sortField, sortDirection }) => {
  return (
    <thead className="table-light text-center">
      <tr style={{ verticalAlign: 'middle' }}>
        <th rowspan="2">Bank</th>
        {selectedField === 'All' || selectedField === 'Telegraphic Transfers' ? 
          <th colspan="2">Telegraphic Transfers</th> : null
        }
        {selectedField === 'All' || selectedField === 'Currency' ? 
          <th colspan="2">Currency</th> : null
        }
        {selectedField === 'All' || selectedField === 'Cheques' ? 
          <th colspan="2">Cheques</th> : null
        }                       
        <th rowspan="2">
          <OverlayTrigger placement="top" overlay={<Tooltip>Information</Tooltip>}>
            <span className="bi bi-info-circle text-dark" style={{ cursor: 'pointer' }}></span>
          </OverlayTrigger>
        </th>
      </tr>
      <tr>
        {selectedField === 'All' || selectedField === 'Telegraphic Transfers' ? 
          <>            
            <SortableTableHeaderCell title="Buying" field="tt_buying" sortField={sortField} sortDirection={sortDirection} onHeaderClick={onHeaderClick} />
            <SortableTableHeaderCell title="Selling" field="tt_selling" sortField={sortField} sortDirection={sortDirection} onHeaderClick={onHeaderClick} />
          </> : null
        }
        {selectedField === 'All' || selectedField === 'Currency' ? 
          <>
            <SortableTableHeaderCell title="Buying" field="cur_buying" sortField={sortField} sortDirection={sortDirection} onHeaderClick={onHeaderClick} />
            <SortableTableHeaderCell title="Selling" field="cur_selling" sortField={sortField} sortDirection={sortDirection} onHeaderClick={onHeaderClick} />
          </> : null
        }
        {selectedField === 'All' || selectedField === 'Cheques' ? 
          <>
            <SortableTableHeaderCell title="Buying" field="dd_buying" sortField={sortField} sortDirection={sortDirection} onHeaderClick={onHeaderClick} />
            <SortableTableHeaderCell title="Selling" field="dd_selling" sortField={sortField} sortDirection={sortDirection} onHeaderClick={onHeaderClick} />
          </> : null
        }                       
      </tr>          
    </thead>    
  );
}

export default TableHeader;