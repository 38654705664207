// CurrencySelector.js
import React from 'react';
import Form from 'react-bootstrap/Form';
import FloatingLabel from 'react-bootstrap/FloatingLabel';
import Placeholder from 'react-bootstrap/Placeholder';
import Button from 'react-bootstrap/Button';
import Card from 'react-bootstrap/Card';

const CurrencySelector = ({ currencies, selectedCurrency, onCurrencyChange }) => {
  return (
      currencies.length > 0 ? (
          <FloatingLabel controlId="floatingSelectCurrency" label="Currency">    
              <Form.Select value={selectedCurrency} onChange={onCurrencyChange} aria-label="Currency dropdown">
                  {currencies.map((currency, index) =>
                      <option key={index} value={currency}>{currency}</option>
                  )}
              </Form.Select>
          </FloatingLabel>
      ) : (
          <Card>
              <Card.Body>
                  <Card.Text>    
                      <Placeholder animation="glow">
                          <Placeholder xs={3} />
                      </Placeholder>
                  </Card.Text>
              </Card.Body>
          </Card>
      )
  );
};

export default CurrencySelector;