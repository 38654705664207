import React from 'react';
import RateCell from './RateCell';

const ExchangeRatesRows = ({ exchangeRates, selectedField, bankMeta, handleIconClick, highestTtBuying, lowestTtBuying, highestTtSelling, lowestTtSelling, highestCurBuying, lowestCurBuying, highestCurSelling, lowestCurSelling, highestDdBuying, lowestDdBuying, highestDdSelling, lowestDdSelling }) => {
  return (
    <tbody>
      {exchangeRates
        .filter(rate => {
          if (selectedField === 'All') return true;
          if (selectedField === 'Telegraphic Transfers' && (rate.tt_buying || rate.tt_selling)) return true;
          if (selectedField === 'Currency' && (rate.cur_buying || rate.cur_selling)) return true;
          if (selectedField === 'Cheques' && (rate.dd_buying || rate.dd_selling)) return true;
          return false;
        })
        .map((rate, index) =>
          <tr key={index}>
            <td>
              {bankMeta[rate.bank_name] ? bankMeta[rate.bank_name].name_short : rate.bank_name}
            </td>
            {['All', 'Telegraphic Transfers'].includes(selectedField) && 
              <>
                <td>
                  <RateCell value={rate.tt_buying} highest={highestTtBuying} lowest={lowestTtBuying} highlightHighest={true} />
                </td>
                <td>
                  <RateCell value={rate.tt_selling} highest={highestTtSelling} lowest={lowestTtSelling} highlightHighest={false} />
                </td>
              </>
            }
            {['All', 'Currency'].includes(selectedField) && 
              <>
                <td>
                  <RateCell value={rate.cur_buying} highest={highestCurBuying} lowest={lowestCurBuying} highlightHighest={true} />
                </td>
                <td>
                  <RateCell value={rate.cur_selling} highest={highestCurSelling} lowest={lowestCurSelling} highlightHighest={false} />
                </td>
              </>
            }
            {['All', 'Cheques'].includes(selectedField) && 
              <>
                <td>
                  <RateCell value={rate.dd_buying} highest={highestDdBuying} lowest={lowestDdBuying} highlightHighest={true} />
                </td>
                <td>
                  <RateCell value={rate.dd_selling} highest={highestDdSelling} lowest={lowestDdSelling} highlightHighest={false} />
                </td>
              </>
            }
            <td style={{ textAlign: 'center' }}>                  
                <span 
                  className={`bi bi-info-circle ${new Date().getTime() - rate.timestamp > 19 * 60 * 60 * 1000 ? "text-warning" : "text-success"}`}
                  style={{ fontWeight: 'bold', cursor: 'pointer' }}
                  onClick={() => handleIconClick(rate.bank_name, rate.timestamp, bankMeta[rate.bank_name].exchange_source)}
                >
                </span>
            </td>
          </tr>
        )}
    </tbody>
  );
}

export default ExchangeRatesRows;