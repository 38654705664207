// Navbar.js
import React from 'react';
import Navbar from 'react-bootstrap/Navbar';
import Container from 'react-bootstrap/Container';
import Image from 'react-bootstrap/Image';
import logo from '../logo.svg';

const NavbarComponent = () => (
    <Navbar expand="xl" className="bg-dark mb-3">
    <Container fluid>
      <Navbar.Brand className="text-white m-auto">
        <Image
          alt="Rates Digest Logo"
          src={logo}
          width="30"
          height="30"
          className="d-inline-block align-top"
        />{' '}
        Rates Digest
      </Navbar.Brand>
    </Container>
  </Navbar>
  );

export default NavbarComponent;