import React from 'react';

const SortableTableHeaderCell = ({ title, field, sortField, sortDirection, onHeaderClick }) => {
  return (
    <th onClick={() => onHeaderClick(field)} style={{ position: 'relative', cursor: 'pointer' }}>
      <div>
        <span>{title}</span>
        <span style={{ float: 'right' }}>
          {sortField === field ? (
            sortDirection === 'asc' ? 
            <i className="bi bi-caret-up-square-fill text-secondary"></i> : 
            <i className="bi bi-caret-down-square-fill text-secondary"></i>
          ) : (
            <i className="bi bi-filter text-secondary"></i>
          )}
        </span>
      </div>
    </th>
  );
}

export default SortableTableHeaderCell;