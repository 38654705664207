import React from 'react';
import Container from 'react-bootstrap/Container';

const Footer = () => {
  return (
    <footer className="footer bg-dark py-3 mt-auto text-center">
      <Container>
        <span className="text-light">For suggestions, send an email to <a href="mailto:info@ratesdigest.com" target="_blank" rel="noopener noreferrer" style={{color: 'white', textDecoration: 'none'}} onMouseOver={(e) => e.target.style.textDecoration = 'underline'} onMouseOut={(e) => e.target.style.textDecoration = 'none'}>info@ratesdigest.com</a>.</span>
      </Container>
    </footer>
  );
}

export default Footer;