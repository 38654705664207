import React from 'react';
import { Toast as BootstrapToast } from 'react-bootstrap';

const Toast = ({ onClose, show, delay, autohide, toastMessage }) => {
  return (
    <div className="position-fixed top-50 start-50" style={{ transform: 'translate(-50%, -50%)', zIndex: 1050 }}>
        <BootstrapToast onClose={onClose} show={show} delay={delay} autohide>
        <BootstrapToast.Header>
            <strong className="me-auto">Network Error</strong>
        </BootstrapToast.Header>
        <BootstrapToast.Body>{toastMessage}</BootstrapToast.Body>
        </BootstrapToast>
    </div>
  );
}

export default Toast;