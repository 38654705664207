import React from 'react';
import Placeholder from 'react-bootstrap/Placeholder';
import PlaceholderCell from './PlaceholderCell';

const PlaceholderRows = ({selectedField}) => {
  return (
    <tbody>
      {[...Array(15)].map((_, i) => (
        <tr key={i}>
          <td>
            <Placeholder animation="glow">
              <Placeholder xs={4} />
            </Placeholder>
          </td>
          {(selectedField === 'All' || selectedField === 'Telegraphic Transfers') && 
            <>
              <PlaceholderCell />
              <PlaceholderCell />
            </>
          }
          {(selectedField === 'All' || selectedField === 'Currency') && 
            <>
              <PlaceholderCell />
              <PlaceholderCell />
            </>
          }
          {(selectedField === 'All' || selectedField === 'Cheques') && 
            <>
              <PlaceholderCell />
              <PlaceholderCell />
            </>
          }
          <td>
            <Placeholder animation="glow">
              <Placeholder xs={2} />
            </Placeholder>
          </td>
        </tr>
      ))}
    </tbody>
  );
}

export default PlaceholderRows;