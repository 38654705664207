// FieldSelector.js
import React from 'react';
import Form from 'react-bootstrap/Form';
import FloatingLabel from 'react-bootstrap/FloatingLabel';

const FieldSelector = ({ selectedField, onFieldChange }) => (
  <FloatingLabel controlId="floatingSelectFields" label="Fields">
    <Form.Select aria-label="Fields dropdown" onChange={onFieldChange} value={selectedField || "Telegraphic Transfers"}>
      <option value="All">All</option>
      <option value="Telegraphic Transfers">Telegraphic Transfers</option>
      <option value="Currency">Currency</option>
      <option value="Cheques">Cheques</option>
    </Form.Select>
  </FloatingLabel>
);

export default FieldSelector;