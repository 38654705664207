import React from 'react';
import Placeholder from 'react-bootstrap/Placeholder';

const PlaceholderCell = () => (
    <td>
      <Placeholder animation="glow">
        <Placeholder xs={4} />
      </Placeholder>
    </td>
  );

export default PlaceholderCell;